/* ==========================================================================
Tools
  > Cards
========================================================================== */

// Base

@mixin t-card {
  position: relative;
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.16);
  overflow: hidden;

  &:after {
    content: '\f178';
    position: absolute;
    bottom: 20px;
    right: 24px;
    font-family: var(--font--awesome);
    font-size: 24px;
    font-weight: 400;
    color: var(--color--primary);
    transition: right 0.3s ease-out;
  }

  &:focus-visible {
    &:after {
      right: 24px;
    }
    .c-card__text {
      top: 0;
    }
  }

  @media (hover: hover) {
    &:after {
      top: 24px;
      bottom: auto;
      right: -24px;
    }
    &:hover {
      &:after {
        right: 24px;
      }
      .c-card__text {
        top: 0;
      }
    }
  }

  ul {
    margin-bottom: 24px;
  }

  // &.c-card--bg--white {
  //   background: #fff;
  //   .c-card__body * {
  //     color: var(--color--primary);
  //   }
  // }

  &.c-card--bg--tertiary--grey {
    background: var(--color--tertiary--grey);
  }

  &.c-card--arrow--white {
    &:after {
      color: #fff;
    }
  }

  &__text {
    width: 100%;
    height: 100%;
    padding: 24px 48px 24px 24px;
    font-weight: 200;
    line-height: 1.4;
    color: #fff;
    background-color: var(--color--primary);
    transition: top 0.3s ease-out;

    @media (hover: hover) {
      position: absolute;
      top: 100%;
      left: 0;
      height: 100%;
    }
  }

  // Media (If image)

  &.c-card--image {
    .c-card__media {
      @include fluid-aspect(4 3);
    }
  }

  .c-btn--plain {
    text-align: left;

    @include bp-small {
      margin-left: auto;
      text-align: right;
    }
  }
}

// Media

@mixin t-card__media {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  @include fluid-aspect(3 2);

  .c-icon {
    font-size: 64px;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  // Offset

  // &.offset {
  //   padding: 0;
  // }

  // Media - Open

  .c-media--open {
    text-align: center;

    img {
      width: auto;
    }
  }

  // Media - Book

  &.c-media--book {
    img {
      max-width: 128px;
    }
  }
}

// Media - LMS

@mixin t-card__media--lms {
  border-bottom: 1px solid rgba(144, 157, 185, 0.33);

  @include bp-max-medium {
    margin: -24px -24px 24px -24px;
    padding-bottom: 8px;
  }

  @include bp-medium {
    margin: -24px 24px -24px -24px;
    padding: 0 24px;
    border-bottom: none;
    border-right: 1px solid rgba(144, 157, 185, 0.33);
  }
}

// Body

@mixin t-card__body {
  padding: 8px 24px;
  background: #fff;
  z-index: 1;

  p {
    opacity: 0.8;
  }

  a:hover {
    text-decoration: underline;
  }

  // Label

  .c-card__label {
    display: inline-block;
    background-color: var(--color--primary);
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    padding: 0 8px;
    min-height: 24px;
    line-height: 24px;
    text-transform: uppercase;
    margin-bottom: 16px;

    .c-icon {
      display: inline-block;
      position: relative;
      top: 1px;
      margin: 0 2px 0 0;
      font-weight: 400;
    }
  }

  .h2 {
    font-size: 28px;
    line-height: 34px;
    letter-spacing: -0.224px;
    margin-bottom: 8px;
  }

  .h3 {
    font-size: 20px;
    letter-spacing: -0.224px;
    margin-bottom: 0;
    @include bp-full {
      font-size: 23px;
      line-height: 1.75;
    }
  }

  .h4 {
    font-size: 18px;
    margin-bottom: 8px;
    color: #444;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

// Action

@mixin t-card__action {
  display: flex;
  padding-top: 24px;

  .c-btn {
    //color: var(--color--primary);
  }
}

// Sub Text

@mixin t-card__sub-text {
  padding-top: 24px;

  p {
    margin: 0;
    border-top: 1px solid var(--color--grey--medium);
    padding-top: 24px;
    font-weight: 300;
    font-style: italic;
  }
}

// Image

@mixin t-card--image {
  .c-card__media {
    overflow: hidden;
    margin: -24px -24px 24px -24px;
    border-radius: var(--global__radius) var(--global__radius) 0 0;
  }
}

// No Image

@mixin t-card--no-image {
  .c-card__media {
    display: none;
  }
}

// Borders (default - tertiary blue))

@mixin t-card--borders {
  //border-left: none;

  &:before {
    display: none;
    content: '';
    background: var(--color--primary);
    border-radius: var(--global__radius) 0 0 var(--global__radius);
    width: 8px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
}

// Vertical

@mixin t-card--vertical {
  flex-direction: column;
  height: 100%;
  // padding: 24px;

  // Media (if image)

  &.c-card--image {
    .c-card__media {
      @include fluid-aspect(4 3);
      border-radius: var(--global__radius) var(--global__radius) 0 0;
      width: calc(100% + 48px);
      margin-top: -24px;
      margin-left: -24px;
      margin-right: -24px;
      margin-bottom: 24px;
      min-width: unset;
      min-height: unset;
    }
  }

  .c-card__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  // Body

  .c-card__body {
    @media (hover) {
      flex-grow: 1;
    }
  }
}

// Horizontal

@mixin t-card--horizontal {
  flex-direction: row;

  // Media

  &.c-card--image .c-card__media {
    position: relative;
    margin-right: 24px;
    margin-bottom: -24px;
    padding-bottom: 0; // Let card content determine card height
    min-height: 256px; // If no content, ensure card doesn't collapse
    width: 34.3450479233227%;
    min-width: 192px;
    border-radius: var(--global__radius) 0 0 var(--global__radius);
  }

  // Media - Product

  &.c-card--product {
    .c-card__media {
      width: 112px;
      margin: 0;
      padding: 24px;

      &.u-b {
        border-bottom: none;
        border-right: 1px solid rgba(144, 157, 185, 0.33);
      }
    }
  }

  .c-card__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  // Body

  .c-card__body {
    flex-grow: 1;
    padding: 24px 24px 24px 0;
  }

  @include bp-medium {
    &.c-card--lms {
      .c-card__action {
        padding-top: 40px;
      }
    }
  }
}
