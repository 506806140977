/* ==========================================================================
Components
  > Cards
========================================================================== */

// Base

.c-card {
  @include t-card;
  @include t-card--vertical;

  // Media

  .c-card__media {
    @include t-card__media;
  }

  // Body

  .c-card__body {
    @include t-card__body;
  }

  // Action

  .c-card__action {
    @include t-card__action;
  }

  // Sub Text

  .c-card__sub-text {
    @include t-card__sub-text;
  }

  // Image

  &.c-card--image {
    @include t-card--image;
  }

  // No Image

  &.c-card--no-image {
    @include t-card--no-image;
  }

  // Borders

  &.c-card--b--l {
    @include t-card--borders;
  }

  &.c-card--elevated {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  }

  &--icon {
    .c-card__media {
      .fal,
      .far,
      .fab {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: auto;
        height: auto;
        font-size: 100px;
      }
    }

    @media (hover: hover) {
      &:hover {
        .c-card__media {
          color: #fff;
          background-color: var(--color--primary--hover);
        }
      }
    }
    &:focus-visible {
      .c-card__media {
        color: #fff;
        background-color: var(--color--primary--hover);
      }
    }
  }
}

// @xl

@include bp-xlarge {
  .c-card--flow--dynamic {
    @include t-card--horizontal;
  }
}

/* ==========================================================================
  2 col - RR (or left)
========================================================================== */

// 1 card

.c-article--main {
  &.l8,
  &.l9 {
    .c-card--01 {
      .c-card.c-card--image {
        @include bp-medium {
          @include t-card--horizontal;
        }
      }
    }
  }
}

// 2 cards

.c-article--main.l8 {
  .c-cards--02 {
    @include bp-medium {
      display: flex;

      .c-card {
        @include t-card--vertical;
      }
    }
  }
}

// 3 cards

.c-article--main.l8 {
  .c-cards--03 {
    @include bp-medium {
      .col {
        @include t-reset--col--100;
      }
      .c-card {
        @include t-card--horizontal;
      }
    }

    @include bp-large {
      display: block;
    }

    @include bp-xlarge {
      display: flex;

      .col {
        float: left;
        width: 33.333% !important;
      }
      .c-card {
        @include t-card--vertical;
      }
    }
  }
}

/* ==========================================================================
  Full Width & 2 col
========================================================================== */

// 1 card

.c-article--main {
  &.c-col--100,
  &.l9 {
    .c-card--01 {
      .c-card.c-card--image {
        @include bp-medium {
          @include t-card--horizontal;
        }
      }
    }
  }
}

// 2 cards

.c-article--main {
  &.c-col--100,
  &.l9 {
    .c-cards--02 {
      @include bp-medium {
        display: flex;

        .c-card {
          @include t-card--vertical;
        }
      }

      @include bp-xlarge {
        .c-card {
          @include t-card--horizontal;
        }
      }
    }
  }
}

// 3 cards

.c-article--main {
  &.c-col--100,
  &.l9 {
    .c-cards--03 {
      @include bp-medium {
        .col {
          @include t-reset--col--100;
        }
        .c-card {
          @include t-card--horizontal;
        }
      }

      @include bp-large {
        display: block;
      }

      @include bp-xlarge {
        display: flex;

        .col {
          float: left;
          width: 33.333% !important;
        }
        .c-card {
          @include t-card--vertical;
        }
      }
    }
  }
}

/* ==========================================================================
  Full Bleed
========================================================================== */

// 1 card

.c-article--main.c-col--fb {
  .c-card--01 {
    .c-card.c-card--image {
      @include bp-medium {
        @include t-card--horizontal;
      }
    }
  }
}

// 2 cards

.c-article--main.c-col--fb {
  .c-cards--02 {
    @include bp-medium {
      display: flex;

      .c-card {
        @include t-card--vertical;
      }
    }

    @include bp-xlarge {
      .c-card {
        @include t-card--horizontal;
      }
    }
  }
}

// 3 cards

.c-article--main.c-col--fb {
  padding: 0; // Needs to stay - Still see form builder pages as these are losing padding.

  .c-cards--03,
  .c-cards--04 {
    @include bp-medium {
      .col {
        @include t-reset--col--100;
      }
      .c-card {
        @include t-card--horizontal;
      }
    }

    @include bp-large {
      display: block;
    }

    @include bp-xlarge {
      display: flex;

      .col {
        float: left;
        margin-left: 0;
      }
      .c-card {
        @include t-card--vertical;
      }
    }
  }

  @include bp-xlarge {
    .c-cards--03 .col {
      width: 33.333% !important;
    }

    .c-cards--04 .col {
      width: 25% !important;
    }
  }
}

/* ==========================================================================
  Horizontal
========================================================================== */

// @sm

@include bp-small-medium {
  .c-card--horizontal--sm {
    @include t-card--horizontal;
  }

  .u-height--unset--sm {
    min-height: unset !important;
  }
}

// @m

@include bp-medium {
  .c-card--horizontal--m {
    @include t-card--horizontal;
  }
}

// @ml

@include bp-medium-large {
  .c-card--horizontal--ml {
    @include t-card--horizontal;
  }
}

// @l

@include bp-large {
  .c-card--horizontal--l {
    @include t-card--horizontal;
  }
}

// @xxl

@include bp-xxlarge {
  .c-card--horizontal--xxl {
    @include t-card--horizontal;
  }
}

/* ==========================================================================
  Vertical
========================================================================== */

// @sm

@include bp-medium-large {
  .c-card--vertical--ml {
    @include t-card--vertical;
  }
}

// @l

@include bp-large {
  .c-card--vertical--l {
    @include t-card--vertical;
  }
}

// Cards on bg img

.has--bg--img {
  .c-card {
    @include shadow(3);
    border: none;
  }

  .h3 {
    color: #fff;
  }
}
